
import Vue from 'vue';
import { SwarmService } from '@/services/swarm.service';

interface Model {
	_id: string;
}

export default Vue.extend({
	props: {
		collection: {
			type: String
		},
		schema: {
			type: Object
		},
		editing: {
			type: Boolean
		}
	},
	data: () => ({
		dialog: false,
		loading: false,
		tagging: false,
		model: {},
		allTags: [],
		uploads: []
	}),
	methods: {
		isString(field: string) {
			return typeof this.schema[field] === 'string';
		},
		isBoolean(field: string) {
			return typeof this.schema[field] === 'boolean';
		},
		isArray(field: string) {
			return Array.isArray(this.schema[field]);
		},
		isNumber(field: string) {
			return typeof this.schema[field] === 'number';
		},
		isUrl(field: string) {
			return field.indexOf('Url') > -1;
		},
		loadTags() {
			return SwarmService.getCollection('tags').then((data) => {
				this.allTags = data;
			});
		},
		loadData() {
			// load the tags
			this.loading = true;

			const promises = [
				this.loadTags()
			];

			return Promise.all(promises).finally(() => {
				this.loading = false;
				this.model = Object.assign({}, this.schema);
			});
		},
		handleSave() {
			if (this.editing) {
				const model = this.model as Model;
				// SwarmService.uploadFile()
				// debugger;
				this.uploads.map(file => SwarmService.uploadFile(file, this.collection, 'avatar'));
				return SwarmService.updateItem(this.collection, model, model._id)
					.then(this.handleClose);
			}
			return SwarmService.createItem(this.collection, this.model)
				.then(this.handleClose);
		},
		handleFileUpload(file: never) {
			// set to model
			this.uploads.push(file);
		},
		handleClose() {
			this.dialog = false;
			this.$emit('refresh');
		},
		handleToggle() {
			this.dialog = true;
			this.loadData();
		}
	}
});
